import React, { useState, useEffect } from "react";
import Typical from "react-typical";

const projects = [
  {
    id: 1,
    company: "Safaricom Ethiopia",
    name: "Aleta Wondo,3 Meter DCP Test",
    images: [
      "/images/project-a/aleta.jpg",
      "/images/project-a/aleta2.jpg",
      "/images/project-a/aleta3.jpg",
    ],
  },
  {
    id: 2,
    company: "Safaricom Ethiopia",
    name: "Gammo 3 Meter DCP Test",
    images: [
      "/images/project-b/Safaricom (8).jpg",
      "/images/project-b/Safaricom (9).jpg",
      "/images/project-b/Safaricom (10).jpg",
    ],
  },
  {
    id: 3,
    company: "Mosa Real State",
    name: "Addis Ababa, Two bore hole 12 meter",
    images: [
      "/images/project-c/Case2 (1).jpg",
      "/images/project-c/Case2 (2).jpg",
      "/images/project-c/Case2 (3).jpg",
    ],
  },
];

function Projects() {
  return (
    <div className="bg-white text-black flex flex-col lg:items-center py-10 px-8">
      <h1 className="text-2xl font-bold text-left text-black mb-2">
        Our Projects
      </h1>
      <Typical
        steps={["Building Pathways, ", 2000, "Shaping Landscapes", 2000]}
        loop={Infinity}
        wrapper="h2"
        className="text-3xl font-semibold text-green-800 mb-4 text-left"
      />
      <div className="relative overflow-hidden w-full max-w-7xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 auto-slide-animation">
          {projects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
}

function ProjectCard({ project }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % project.images.length
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [project.images.length]);

  return (
    <div className="bg-gray-200 square-card shadow-lg w-full transform">
      <img
        src={project.images[currentImageIndex]} // Display the current image
        alt={project.name}
        className="w-full h-40 object-cover" // Ensures the image fills the card without distortion
      />
      <div className="mt-2 text-left p-2">
        <h3 className="text-xl font-semibold text-green-800 mb-1">
          {project.company}
        </h3>
        <h3 className="text-xl font-semibold text-green-600 mb-1">
          {project.name}
        </h3>
        {/* <p className="text-gray-700">
          {project.images.length} Images Available
        </p> */}
      </div>
    </div>
  );
}

export default Projects;
