// src/components/Contact.js
import { useState } from "react";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaTelegramPlane,
} from "react-icons/fa"; // Importing icons from react-icons

const Contact = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    const formData = new FormData(event.target);
    formData.append("access_key", "b420d6d5-3f66-42aa-a1fc-0a47fa43d610");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      setResult(data.message);
    }
  };

  return (
    <div className="max-w-[90%] mx-auto my-20 flex flex-col lg:flex-row lg:justify-between items-center space-y-8 lg:space-y-0 lg:space-x-8 sm:w-full">
      {/* Contact Information */}
      <div className="flex-1 text-[#4e3b31] space-y-5">
        <h3 className="text-2xl font-medium flex items-center">
          Send Us A Message{" "}
          <FaEnvelope className="ml-5 w-8 h-8 text-green-900" />
        </h3>
        <p className="max-w-md leading-relaxed">
          We’d love to hear from you! If you have any questions, comments, or
          need assistance, please feel free to reach us. Our team is here to
          help and will get back to you as soon as possible.
        </p>
        <ul className="space-y-5">
          <li className="flex items-center space-x-5">
            <FaEnvelope className="w-8 h-8 text-green-900" />
            <a
              href="mailto:Gravelgeotechnicalworks@gmail.com"
              className="hover:underline text-green-900"
            >
              Gravelgeotechnicalworks@gmail.com
            </a>
          </li>
          <li className="flex items-center space-x-5">
            <FaPhoneAlt className="w-8 h-8 text-green-900" />
            <a
              href="tel:+251-9-44-75-10-81"
              className="hover:underline text-green-900"
            >
              +251-9-44-75-10-81
            </a>
          </li>
          <li className="flex items-center space-x-5">
            <FaTelegramPlane className="w-8 h-8 text-green-900" />
            <a
              href="https://t.me/bemyone"
              className="hover:underline text-green-900"
            >
              Gravel Geo Technicals
            </a>
          </li>
          <li className="flex items-center space-x-5">
            <FaMapMarkerAlt className="w-8 h-8 text-green-900" />
            Metebaber Building, 4th floor Office Number 406
          </li>
        </ul>
      </div>

      {/* Contact Form */}
      <div className="flex-1 w-full  space-y-4">
        <form
          action="https://formspree.io/f/mwkgaqgl"
          method="POST"
          onSubmit={onSubmit}
          className="space-y-4 bg-white p-8 rounded-lg shadow-lg sm:w-full"
        >
          <div>
            <label className="block text-sm font-medium text-green-900 mb-1">
              Your Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              required
              className="w-full bg-green-100 px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-green-900 mb-1">
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              placeholder="Enter Your Mobile Number"
              required
              className="w-full bg-green-100 px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-green-900 mb-1">
              Write Your Message Here
            </label>
            <textarea
              name="message"
              placeholder="Enter Your Message"
              rows="6"
              required
              className="w-full bg-green-100 px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-green-900  text-white font-semibold py-3 rounded-lg hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
        <span className="block text-green-600 mt-4">{result}</span>
      </div>
    </div>
  );
};

export default Contact;
