import React from "react";

function About() {
  return (
    <div className="min-h-screen bg-white p-10 flex flex-col md:flex-row">
      <div className="md:w-1/2 flex items-center justify-center p-6">
        <div className="w-full">
          <h1 className="text-4xl font-bold text-black  text-left mb-4">
            About Us
          </h1>
          <p className="text-black text-lg leading-relaxed mb-6 text-left">
            Gravel is a leader in geotechnical and soil testing services,
            committed to providing accurate and reliable analysis to support
            safe and sustainable construction projects. Our team of experts has
            years of experience working with clients from various industries,
            ensuring that each project is completed with professionalism and
            expertise.
          </p>
        </div>
      </div>
      <div className="md:w-1/2 flex items-center justify-center p-6">
        <img
          src="/images/Gravellogo.jpg" // Ensure the image path is correct
          alt="About Gravel"
          className="w-full h-auto object-cover rounded-lg shadow-xl shadow-black" // Green shadow for a distinct look
        />
      </div>
    </div>
  );
}

export default About;
