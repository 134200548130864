import React, { useState } from "react";
import { Link } from "react-scroll";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-green-800 text-white p-4 fixed top-0 w-full z-50 shadow-lg ">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo and Title */}
        <div className="flex items-center">
          <img
            src="./images/Gravellogo.jpg"
            alt="Gravel Logo"
            className="h-10 mr-2 rounded-full"
          />
          <Link
            to="home"
            smooth={true}
            duration={500}
            offset={-70} // Adjust this value to control where it stops
            className="text-lg font-bold cursor-pointer font-montserrat"
          >
            Geo-Technical Works
          </Link>
        </div>

        {/* Desktop Links */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="home"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-gray-300 cursor-pointer"
          >
            Home
          </Link>
          <Link
            to="about"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-gray-300 cursor-pointer"
          >
            About
          </Link>
          <Link
            to="services"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-gray-300 cursor-pointer"
          >
            Services
          </Link>
          <Link
            to="projects"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-gray-300 cursor-pointer"
          >
            Projects
          </Link>
          <Link
            to="testimonials"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-gray-300 cursor-pointer"
          >
            Testimonials
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-gray-300 cursor-pointer"
          >
            Contact
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-2xl focus:outline-none"
        >
          {isOpen ? "✖" : "☰"}
        </button>
      </div>

      {/* Sidebar Menu for Mobile */}
      <div
        className={`fixed top-0 right-0 h-full bg-green-900 text-white transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 md:hidden z-50 p-4 w-64`}
      >
        <button
          onClick={toggleMenu}
          className="text-xl font-bold mb-4 focus:outline-none"
        >
          ✖
        </button>
        <Link
          to="home"
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
          className="block mb-4 hover:text-gray-300 cursor-pointer"
        >
          Home
        </Link>
        <Link
          to="about"
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
          className="block mb-4 hover:text-gray-300 cursor-pointer"
        >
          About
        </Link>
        <Link
          to="services"
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
          className="block mb-4 hover:text-gray-300 cursor-pointer"
        >
          Services
        </Link>
        <Link
          to="projects"
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
          className="block mb-4 hover:text-gray-300 cursor-pointer"
        >
          Projects
        </Link>
        <Link
          to="testimonials"
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
          className="block mb-4 hover:text-gray-300 cursor-pointer"
        >
          Testimonials
        </Link>
        <Link
          to="contact"
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleMenu}
          className="block mb-4 hover:text-gray-300 cursor-pointer"
        >
          Contact
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
