// src/pages/Home.js
import React from "react";
import Typical from "react-typical";

function Home() {
  const handleButtonClick = () => {
    // Initiate the call
    window.location.href = "tel:+251944751081"; // Replace with your phone number
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: "url('/images/wallpaper.jpg')" }}
    >
      <div className="bg-black bg-opacity-50 lg:px-28 lg:py-24 p-8 rounded-lg shadow-lg mx-4">
        <h1 className="text-5xl font-bold mb-4 text-green-400">
          <Typical
            steps={[
              "Welcome to Gravel",
              1000,
              "Your trusted partner for soil testing.",
              1000,
              "Call Us Now",
              3500,
            ]}
            loop={Infinity}
            wrapper="p"
          />
        </h1>
        <p className="text-lg text-gray-300 mb-6">
          Your trusted partner for soil testing.
        </p>
        <button
          onClick={handleButtonClick} // Call onClick handler
          className="text-white font-bold px-6 py-3 rounded-sm bg-green-500 transition-transform transform duration-300 hover:bg-green-400"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default Home;
