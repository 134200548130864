import React, { useState, useEffect } from "react";

const testimonials = [
  {
    id: 1,
    name: "City Government of Addis Ababa & Construction Works Bureau",
    feedback:
      "Our company engaged Telsem Consulting Plc for a project in Arada Sub-city, specifically around Afincho-Ber, with a scale of 14,435,436.66, intended for entertainment purposes. We are delighted to share our experience as Telsem Consulting Plc demonstrated exceptional professionalism and competence throughout the project. Their expertise and attention to detail ensured the successful completion of the project to our utmost satisfaction. We highly recommend Telsem Consulting Plc for their outstanding services.",
    image: "/images/design.png", // Update with your image path
    caption: "April 5, 2022",
  },
  {
    id: 2,
    name: "City Government of Addis Ababa & Construction Works Bureau",
    feedback:
      "As the owners of the project, our bureau undertook the construction of Yeka Abado G+4 Primary School, seeing it through to completion. Throughout this endeavor, we enlisted the expertise of Telsem Consulting Company for their professional consulting services.",
    image: "/images/design.png", // Update with your image path
    caption: "January 13, 2022",
  },
  {
    id: 3,
    name: "City Government of Addis Ababa & Construction Works Bureau",
    feedback:
      "We're delighted to share our positive experience collaborating with Telsem Consulting on a large-scale school project in Bole Subcity. Their expert guidance and dedication were instrumental in ensuring the success of our endeavor. From initial planning to project completion, Telsem's professionalism and expertise shone through, making the entire process seamless and enjoyable. We highly recommend Telsem Consulting for any project requiring top-notch consulting services.",
    image: "/images/design.png", // Update with your image path
    caption: "April 1, 2022",
  },
];

function Testimonial() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Slide every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className=" bg-white p-10 flex flex-col items-center">
      <h1 className="text-3xl font-bold text-center mb-8">Testimonials</h1>
      <div className="relative w-full max-w-3xl overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="min-w-full p-10 bg-white shadow-md rounded-lg text-center"
            >
              <img
                src={testimonial.image}
                alt={`${testimonial.name}'s photo`}
                className="w-24 h-24 mx-auto rounded-full mb-4"
              />
              <p className="italic mb-4">"{testimonial.feedback}"</p>
              <h4 className="font-semibold text-lg">{testimonial.name}</h4>
              <p className="text-gray-500 text-sm mt-2">
                {testimonial.caption}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* Optional Navigation Buttons */}
      <div className="flex mt-6 space-x-4">
        <button
          onClick={prevSlide}
          className="px-4 py-2 bg-gray-300 rounded-full"
        >
          &lt;
        </button>
        <button
          onClick={nextSlide}
          className="px-4 py-2 bg-gray-300 rounded-full"
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

export default Testimonial;
