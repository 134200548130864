// src/components/Footer.js
import React from "react";

function Footer() {
  return (
    <footer className="bg-green-900 text-white py-6">
      <div className="container mx-auto text-center ">
        <p>&copy; 2024 Gravel. All rights reserved.</p>
        <p>Gravelgeotechnicalworks@gmail.com | +251-9-44-75-10-81</p>
      </div>
    </footer>
  );
}

export default Footer;
