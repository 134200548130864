import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typical from "react-typical"; // Ensure Typical import for animated text

const services = [
  {
    id: 1,
    title: "Soil Testing",
    description: "In-depth soil testing for safe and sustainable construction.",
    images: [
      "/images/services/soil-testing1.jpg",
      "/images/services/soil-testing2.jpg",
      "/images/services/soil-testing3.jpg",
    ],
  },
  {
    id: 2,
    title: "Hammer Test",
    description: "Comprehensive surveys for project planning and development.",
    images: [
      "/images/services/hammer1.jpg",
      "/images/services/hammer2.jpg",
      // "/images/services/geotechnical3.jpg",
    ],
  },
  {
    id: 3,
    title: "Test Pit",
    description: "Assessing environmental factors for responsible development.",
    images: [
      "/images/services/testpit1.jpg",
      "/images/services/testpit2.jpg",
      // "/images/services/environmental3.jpg",
    ],
  },
];

const CustomPrevArrow = ({ onClick }) => (
  <button onClick={onClick} className="slick-arrow slick-prev">
    &#10094; {/* Left Arrow */}
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button onClick={onClick} className="slick-arrow slick-next">
    &#10095; {/* Right Arrow */}
  </button>
);

function Services() {
  const serviceSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one service card at a time in carousel
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const imageSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="bg-white text-black lg:flex lg:flex-col lg:items-center p-10">
      <h1 className="text-2xl font-bold text-left mb-2">Our Services</h1>

      {/* Typing Text */}
      <div className="mb-6 text-center">
        <Typical
          steps={[
            "Explore our expertise!",
            2000,
            "Quality you can rely on",
            2000,
          ]}
          loop={Infinity}
          wrapper="h2"
          className="text-3xl text-left font-semibold text-green-900"
        />
      </div>

      {/* Carousel for Service Cards on Smaller Screens */}
      <div className="md:hidden overflow-hidden ">
        <Slider {...serviceSettings}>
          {services.map((service) => (
            <div
              key={service.id}
              className="bg-slate-100 p-6  rounded-lg shadow-lg text-center overflow-hidden transition-transform transform hover:scale-105"
            >
              <h3 className="text-2xl font-semibold text-black mb-3">
                {service.title}
              </h3>
              <p className="text-black mb-4">{service.description}</p>
              <div className="overflow-hidden rounded-lg">
                <Slider {...imageSettings}>
                  {service.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`${service.title} - ${index + 1}`}
                        className="w-full h-56 object-cover rounded-md transition-transform duration-300"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Display All Cards on Larger Screens */}
      <div className="hidden md:flex flex-wrap justify-center w-full">
        {services.map((service) => (
          <div
            key={service.id}
            className="bg-slate-100 p-6 mx-2 rounded-lg shadow-md  shadow-gray-600 text-center overflow-hidden transition-transform transform hover:scale-105 w-1/4"
          >
            <h3 className="text-2xl font-semibold text-green-400 mb-3">
              {service.title}
            </h3>
            <p className="text-black mb-4">{service.description}</p>
            <div className="overflow-hidden rounded-lg">
              <Slider {...imageSettings}>
                {service.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`${service.title} - ${index + 1}`}
                      className="w-full h-56 object-cover rounded-md transition-transform duration-300"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
